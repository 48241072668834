<template>
  <div id="app">
    <div class="main_bg"></div>
    <v-card
        class="ma-0 pa-0"
        color="#ffffff"
        dark>
        
        <v-app-bar
        app
        :fixed="true"
        color="#ffffff"
        rounded="0"
        dark
        dense>
            <v-row justify="center">
                <v-btn class="mt-1 mr-1" icon to="/home" color="primary" height="30" width="30"><v-icon>mdi-home</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-img
                    :src="require('@/assets/logo3.png')"
                    contain
                    height="40"
                    width="150px"
                    max-width="200"
                />
                <v-spacer></v-spacer>
                <div class="lang_cursor mt-1 mr-1" v-if="this.$i18n.locale=='en'">
                  <v-img 
                    contain
                    :src="require('@/assets/th.png')"
                    height="30"
                    width="30"
                    @click="langThaiClick"
                  />
                </div>
                <div class="lang_cursor mt-1 mr-1" v-else>
                  <v-img 
                    contain
                    :src="require('@/assets/en.png')"
                    height="30"
                    width="30"
                    @click="langEngClick"
                  />
                </div>
            </v-row>
        </v-app-bar>
    </v-card>
    
    <v-container v-if="loading">
        <template>
          <v-row
            width="100%"
            class="fill-height ma-0 pa-0"
            justify="center">
            <v-progress-circular
              indeterminate
              color="#FFD600"
              width="6"
              size="64"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-container>
  
      <v-container fluid class="ma-0 pa-0" v-else>
        <v-layout justify-center>
          <div class="banner"></div>
        </v-layout>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <v-layout justify-center>
          <template v-if="slides != null && slides.length>0">
            <v-sheet
              class="mx-auto mt-n2"
              elevation="0"
              max-width="400"
              width="100%">
              <v-slide-group
                v-model="model"
                class="pa-1"
                active-class="primary"
                center-active>
                <v-slide-item
                  v-for="(slide, index) in slides"
                  :key="index"
                  v-slot="{ active, toggle }"
                  class="ma-0" >
                  <v-card
                    :color="active ? undefined : 'white lighten-1'"
                    class="ma-3"
                    width="200"
                    @click="toggle"
                    dark
                    flat
                    tile>
                    <v-row
                      class="fill-height ma-n2 pa-n2"
                      justify="center">
                    <v-scale-transition>
                      <!-- <a :href="slide.ref_url" target="_self"> -->
                        <v-img
                          contain
                          :src="require(`@/assets/redeem/ecomatcher/${slide.ref_image}`)"
                          @click="showPopup"
                          height="200"
                          max-width="200">
                        </v-img>
                      <!-- </a> -->
                    </v-scale-transition>
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>
          </template>
        </v-layout>

        <v-layout justify-center>
          <!-- <a href="/redeem/ecomatcher/redeem" target="_self"> -->
          <div :class="this.$i18n.locale=='en' ? 'condition_en lang_cursor' : 'condition_th lang_cursor'" @click="showPopup"></div>
          <!-- </a> -->
        </v-layout>

        <template>
          <div class="ma-0 pa-0">
              <v-dialog
                v-model="isShow"
                width="320px"
                max-width="320px">
                <v-card>
                  <v-card-title class="normal--text">{{ $t('redeem_ecomatcher.title_phone') }}</v-card-title>
                  <v-divider></v-divider>
                  <v-row class="ma-1">
                    <v-col cols="12">
                      <v-text-field
                        class="text_input inputNumber"
                        v-model="phoneNo"
                        autocomplete="phoneNo"
                        type="number"
                        ref="txt_phoneno"
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="#1b5e20"
                      text
                      @click="isShow = false"
                    >
                    {{ $t('redeem_ecomatcher.close_button') }}
                    </v-btn>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-btn class="normal--text" color="#1b5e20" @click="confirmRedeem" :disabled="!phoneNo">
                      {{ $t('redeem_ecomatcher.confirm_button') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
          </div>
        </template>

        <ShowOverlay 
          v-model="isShowOverlay">
        </ShowOverlay>
        <ShowMessage 
          v-model="isShowMessage" 
          :msg="showmessage_msg"
          :width="showmessage_width"
          :callBack="showmessage_callback">
        </ShowMessage>
      </v-container>
  </div>
</template>

<script>
import ShowOverlay from "@/components/ShowOverlay"
import ShowMessage from "@/components/ShowMessage"
import api from "@/services/api_villa";

export default {
  name: "RedeemEcomatcher",
  data() {
    return {
      loading: false,
      isShow: false,
      model: null,
      slides: [],
      phoneNo: "",

      isShowOverlay: false,
      isShowMessage: false,
      showmessage_msg: "",
      showmessage_width: 400,
      showmessage_callback: null
    };
  },
  components: {
    ShowOverlay,
    ShowMessage
  },
  mounted() {
    this.loading = true;
    this.setSlide();
    setTimeout(() => {
      this.loading = false;
    }, 1000)
  },
  methods: {
    showMessage(showmessage_msg, showmessage_width) {
        this.showmessage_msg = showmessage_msg;
        this.showmessage_width = showmessage_width;
        this.isShowMessage = true;
    },
    onShowMessageCallBack() {
      this.isShow = false;
    },
    langThaiClick() {
      this.$i18n.locale = 'th';
      localStorage.setItem('lang', 'th');
      this.setSlide();
    },
    langEngClick() {
      this.$i18n.locale = 'en';
      localStorage.setItem('lang', 'en');
      this.setSlide();
    },
    setSlide() {
      let lang = this.$i18n.locale ?? "en";
      this.slides = [];
      for (let i = 1; i <= 4; i++) {
        this.slides.push(
          {
            ref_image: `slide_0${i}_${lang}.jpg`,
            ref_url: "/redeem/ecomatcher/redeem"
          }
        )
      }
    },
    showPopup() {
      try {
        this.showmessage_callback = null;
        this.isShow = true;
        setTimeout(() => {
          this.$refs.txt_phoneno.focus();
        }, 200)
      } catch (error) {
        this.showMessage(error.toString(), 400);
      }
    },
    async confirmRedeem() {
      try {
        this.showmessage_callback = null;
        if (this.phoneNo.trim()=="" || this.phoneNo.trim().length!=10) {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.invalid_phoneno}`, 400);
          return;
        }
        this.isShowOverlay = true;
        let result = await api.sendLinkRedeem(this.phoneNo.trim());
        if (result) {
          this.showmessage_callback = this.onShowMessageCallBack;
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.send_link_redeem_success}`, 400);
          return;
        }
        else {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.invalid_data}`, 400);
          return;
        }

        // let response = await api.addPhone(this.phoneNo.trim());
        // if (response == null) {
        //   this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.invalid_data}`, 400);
        //   return;
        // }
        // let docno = response["docno"] ?? "";
        // let refno = response["refno"] ?? "";
        // if (docno.trim()=="" || refno.trim()=="") {
        //   this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).redeem_ecomatcher.invalid_data}`, 400);
        //   return;
        // }

        // let url = `/redeem/ecomatcher/redeem/${docno}/${refno}`;
        // this.$router.push(url, () => {});
      } catch (error) {
        this.showMessage(error.toString(), 400);
      }
      finally {
        setTimeout(() => {
          this.isShowOverlay = false;
        }, 1000);
      }
    }
  },
}
</script>

<style scoped>
  .main_bg {
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
    color: #ffffff;
    background: linear-gradient(#1b5e20, #e8f5e9);
    /* background: linear-gradient(#e8f5e9, #1b5e20); */
    background-size:100% 100%;
    background-repeat: no-repeat;
    position: fixed;
  }
  .banner {
        background-image: url("../../../assets/redeem/ecomatcher/banner.gif");
        width: 370px;
        height: 160px;
        max-width: 370px;
        margin: 0;
        padding: 0;
        background-size:100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        /* left:0;right:0;top:0;bottom:0; */
    }
  
  .lang_cursor {
    cursor: pointer;
  }

  .condition_th {
    background-image: url("../../../assets/redeem/ecomatcher/condition_th.jpg");
    width: 370px;
    height: 300px;
    /* max-height: 370px; */
    margin: 0;
    padding: 0;
    background-size:100% 100%;
    background-repeat: no-repeat;
    /* position: absolute; */
    position: relative;
    /* left:0;right:0;top:0;bottom:0; */
  }
  .condition_en {
    background-image: url("../../../assets/redeem/ecomatcher/condition_en.jpg");
    width: 370px;
    height: 300px;
    /* max-height: 370px; */
    margin: 0;
    padding: 0;
    background-size:100% 100%;
    background-repeat: no-repeat;
    /* position: absolute; */
    position: relative;
    /* left:0;right:0;top:0;bottom:0; */
  }
  .lang_cursor {
    cursor: pointer;
  }
  .normal--text {
    background-color: #1b5e20;
    color: #FFFFFF;
  }
  .text_input {
    background-color: #FAFAFA;
    color: #1b5e20;
  } 
  .inputNumber >>> input[type='number'] {
    -moz-appearance:textfield;
  }
  .inputNumber >>> input::-webkit-outer-spin-button,
  .inputNumber >>> input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
</style>